const data = {
    table: {
        columns: [
            // {title: '名称', field: 'XM', width: ''},
            // {title: '性别', field: 'XB', width: ''},
            // {title: '年龄', field: 'NL', width: ''},
            // {title: '手机', field: 'DH', width: ''},
            // {title: '身份证', field: 'SFZ', width: ''},
            // {title: '激活状态', field: 'status', width: ''},
            // {title: '起止时间', field: 'date', width: ''},
            {title: '执行医生', field: 'doctor', width: ''},
            {title: '历史方案', field: 'FAMC', width: ''},
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    },
}

export default data
